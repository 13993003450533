import React, { useState } from 'react';
import { Modal, Carousel } from 'react-bootstrap';

import help3 from '../../global/cms/ticket_checker_help3.jpg';
import help4 from '../../global/cms/ticket_checker_help4.jpg';
import help5 from '../../global/cms/ticket_checker_help5.jpg';
import help6 from '../../global/cms/ticket_checker_help6.jpg';
import help7 from '../../global/cms/ticket_checker_help7.jpg';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../components/ticket_checker/help_modal.scss';

const HelpModal = () => {
    return (
        <>
            <ModalWindow linkText="Help">
                <MyCarousel />
            </ModalWindow>
        </>
    );
};

const ModalWindow = props => {
    const [state, setState] = useState({ show: false });

    const handleClose = () => {
        setState({ show: false });
    };

    const handleShow = () => {
        setState({ show: true });
    };

    const handleKeyShow = event => {
        if (event.key === 'Enter') {
            setState({ show: true });
        }
    };

    const handleKeyClose = event => {
        if (event.key === 'Enter') {
            setState({ show: false });
        }
    };

    return (
        <>
            <a
                role="button"
                className="btn btn-block btn-text help"
                tabIndex="0"
                onKeyDown={handleKeyShow}
                onClick={handleShow}
            >
                &#9432; {props.linkText}
            </a>

            <Modal
                dialogClassName="scbz-modal carousel-modal"
                show={state.show}
                onHide={handleClose}
            >
                <Modal.Header>
                    <a
                        className="modal-close-element"
                        title="Close"
                        tabIndex="0"
                        role="button"
                        onKeyDown={handleKeyClose}
                        onClick={handleClose}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} size="3x" />
                    </a>
                </Modal.Header>
                <Modal.Body>{props.children}</Modal.Body>
            </Modal>
        </>
    );
};

const MyCarousel = props => {
    return (
        <Carousel>
            <Carousel.Item>
                <img className="d-block w-100" src={help3} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={help4} alt="Third slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={help5} alt="Fourth slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={help6} alt="Fifth slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={help7} alt="Sixth slide" />
            </Carousel.Item>
        </Carousel>
    );
};

export default HelpModal;
